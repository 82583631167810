import React, { Component } from 'react'

const Docu = props => (
  <div>
    <h1>Slide</h1>
    <div>
      <iframe
        src="https://docs.google.com/presentation/d/e/2PACX-1vS6J6ur7-1Kys_IRjwEb15DJcQNTct15weGtXPhQx94G9eQRIRfj2bOIGiw4s_22nE4Wx4O7YbXNIbY/embed?start=false&loop=false&delayms=60000"
        frameborder="0"
        width={window.$( document ).width()-60}
        height="299"
        allowfullscreen="true"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
      />
    </div>
  </div>
)

export default Docu
