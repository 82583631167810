

import React, { Component } from 'react';

export default function ({type='primary', text})  {
    return (
        <div class={`text-center alert alert-${type}`} role="alert">
  {text}
</div>
    )
}