import React from 'react'
import { Form } from '../coupon'
import Status from '../coupon/Status';

const About = ({
  user
}) => (
  <div>

    <Status text="ใช้ชื่อนี้ในการออกใบกำกับภาษีเต็มรูปแบบเท่านั้น" type="warning"></Status>

    <Form label="Mobile" readonly={true} value={user.mobile} />

    <h1>Bank Infomation</h1>
    <Form
      label="ชื่อธนาคาร"
      readonly={true}
      value={user.bankAccount && user.bankAccount.bankName}
    />
    <Form
      label="ชื่อบัญชี"
      readonly={true}
      value={user.bankAccount&&user.bankAccount.accountName}
    />
    <Form
      label="เลขบัญชี"
      readonly={true}
      value={user.bankAccount&&user.bankAccount.accountNumber}
    />
    <p></p>
  </div>
)

export default About
