import React, { useState, useEffect, useReducer } from 'react'
import moment from 'moment'
import Coupon from '../components/Coupon'
import _ from 'lodash'

const aws = require('../../awsInstance')

function Home({ user = { mobile: '1234' }, history }) {
  const [coupons, setCoupons] = useState([])
  const [coupons_filtered, setCouponsFiltered] = useState([])
  const [tab, setTab] = useState('active')
  const [currentPrice, setCurrentPrice] = useState(-1)
  const [priceGroup, setPriceGroup] = useState([])

  async function fetchCoupon() {
    var params = {
      TableName: aws.TABLE.COUPON_TABLE,
      KeyConditionExpression: `mobile = :mobile`,
      ExpressionAttributeValues: {
        ':mobile': user.mobile
      }
    }
    var data = await aws.documentClient.query(params).promise()
    data.Items = data.Items.sort((a, b) => {
      return parseInt(b.price) - parseInt(a.price) >= 0 ? 1 : -1
    })

    var sorted = data.Items.filter(c => c.status == 'active')

    setCoupons(sorted)
    setCouponsFiltered(sorted)
    var grouped = _.groupBy(sorted, c => c.price)
    
    var _priceGroup = _.reduce(grouped, (acc, values, key) => {
      acc.push({
        qty :  values.length,
        key : key,
        price : key
      })
      return acc
    }, [])
    setPriceGroup(_priceGroup)
    return data
  }
  useEffect(() => {
    fetchCoupon()
  }, [])

  useEffect(() => {
    var res = coupons.filter(c => c.price == currentPrice)
    if (currentPrice == -1) {
      setCouponsFiltered(coupons)
    } else {
      setCouponsFiltered(res)
    }
  }, [currentPrice])

  function useCoupon(couponId) {
    history.push('/coupon/' + couponId)
  }
  return (
    <div class="mb-5">
      <p class="mb-0" style={{ color: 'gray' }}>
        <small>Select Coupons.</small>
      </p>

      <PriceFilter
        currentPrice={currentPrice}
        setCurrentPrice={setCurrentPrice}
        priceGroup={priceGroup}
      />

      <CouponList coupons={coupons_filtered} useCoupon={useCoupon} />
    </div>
  )
}
function CouponList({ coupons = [], useCoupon }) {
  return (
    <div class="mb-5">
      {coupons.map((lineCoupon, i) => {

        let endDateMoment =  moment(lineCoupon.endDate).endOf('day');
        let isExpired = moment().isAfter(endDateMoment, 'second')
        return <Coupon  isExpired={ isExpired} {...lineCoupon} index={i + 1} useCoupon={useCoupon} />
      })}
    </div>
  )
}

function genClass(currentPrice, price) {
  return currentPrice == price
    ? 'page-item flex-fill active'
    : 'page-item flex-fill'
}
function PriceFilter({ currentPrice, setCurrentPrice, priceGroup }) {
  return (
    <nav aria-label="Page navigation example">
      <ul class="pagination">
        <li class={genClass(currentPrice, -1)}>
          <a class="page-link" href="#" onClick={() => setCurrentPrice(-1)}>
            All{' '}
          </a>
        </li>
        {priceGroup.map( g => (<li class={genClass(currentPrice, g.price)}>
          <a class="page-link" href="#" onClick={() => setCurrentPrice(g.price)}>
            ฿{g.price} ({g.qty})
          </a>
        </li>))}
        
        {/* <li class={genClass(currentPrice, 500)}>
          <a class="page-link" href="#" onClick={() => setCurrentPrice(500)}>
            500THB
          </a>
        </li>
        <li class={genClass(currentPrice, 1000)}>
          <a class="page-link" href="#" onClick={() => setCurrentPrice(1000)}>
            1000THB
          </a>
        </li> */}
      </ul>
    </nav>
  )
}

export default Home
