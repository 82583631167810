import React, { Component } from 'react'
import moment from 'moment'
export default function Coupon2({
  price = 0,
  endDate,
  couponId,
  status,
  color,
  index,
  readOnly = false,
  useCoupon,
  isExpired = false
}) {
  return (
    <div class="row mb-3">
      <div class="col-12">
        <div class="card coupon">
          <div class="panel-body">
            <h1 class="display1 ml-4 mb-0">฿{price}.00-
              {isExpired && <span style={{marginRight : 16, color : 'gray', fontStyle : 'italic', float : 'right'}}>(Expired)</span>}
            </h1>
            <div class="col-12">
              <ul class="items">
                <li>กินร้านไหนก็ได้</li>
                <li>ออกใบกำกับภาษี(VAT)เต็มรูปแบบ</li>
                <li>Brands ไม่ซ้ำเดิม</li>
                <li>กินเสร็จส่งบิลเลย</li>
              </ul>
            </div>
          </div>
          <div class="card-footer">
            <div class="coupon-code">
              Code: {couponId}
              {!readOnly  && isExpired === false && <span class="print">
                <a href="#" class="btn btn-link text-right" onClick={()=>useCoupon(couponId) }>
                  Use this
                </a>
              </span>}

              {!readOnly  && isExpired === true && <span class="print">
                 
                 <span class="text-right">Expired</span>
              </span>}

            </div>
            <div class="exp">
              Expires: {moment(endDate).format('MMM DD, YYYY')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
