const AWS = require('aws-sdk')

const SECERT_KEY = 'TEGRBJGHRAO1riegVkCaMbpnXTwjj2+SW8bX5j3f'
const API_KEY = 'AKIAJGDKBGN7WVAJ4Z5Q'
const region = 'ap-southeast-1'

export const TABLE = {
  COUPON_TABLE: 'EatMan-Coupons',
  USERS_TABLE: 'EatMan-Users'
}

 

export const s3 = new AWS.S3({
  region: 'ap-southeast-1',

  accessKeyId: API_KEY,
  secretAccessKey: SECERT_KEY
})
export const documentClient  =new AWS.DynamoDB.DocumentClient({
  region: 'ap-southeast-1',
  endpoint: 'dynamodb.ap-southeast-1.amazonaws.com',

  accessKeyId: API_KEY,
  secretAccessKey: SECERT_KEY
})

export const sns = new AWS.SNS({
  region: 'ap-southeast-1',

  accessKeyId: API_KEY,
  secretAccessKey: SECERT_KEY
})

export const  saveToDynamoDb = async function(tableName, data) {
  var params = {
    TableName: tableName,
    Item: data
  }
  return documentClient.put(params).promise()
  
  // return new Promise((resolve, reject) => {
  //   var params = {
  //     TableName: tableName,
  //     Item: data
  //   }

  //   documentClient.put(params, function(err, data) {
  //     if (err) reject(err)
  //     else console.log(data)

  //     resolve(data)
  //   })
  // })
}

export const updateUser = async function(tableName, data) {
  var params = {
    TableName: tableName,
    Item: data
  }
  return documentClient.put(params).promise()
 }