import React, { Component, useState, useEffect } from 'react'
import { PendingCard } from '../../containers/home'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import Coupon, { Form } from '../../containers/coupon'
import { s3, documentClient, TABLE, sns } from '../../awsInstance'
import moment from 'moment'
import axios from 'axios'
import BankInfo from './bankInfo'

function Approved({ match, coupon, history }) {
  function handleImageOnChange(evt) {
    var file = evt.currentTarget.files[0]
    var fileName = evt.currentTarget.files[0].name
    setInvoice(fileName)
    setUploading(true)

    s3.upload(
      {
        Key: `eatman/${match.params.mobile}/transfered/${moment().format(
          'YYYY-MM-DD'
        )}/${fileName}`,
        Bucket: 'v1-tax',
        Body: file
      },
      function(err, data) {
        setUploading(false)

        if (err) {
          return alert('There was an error uploading your photo: ', err.message)
        }
        setImgUrl(data.Location)
        // alert("Successfully uploaded photo.");

        setTimeout(() => {
          // window.scrollTo(0,document.body.scrollHeight);
          // window.$(window).scrollTop(650)
        }, 400)
      }
    )
  }
  const [dateInput, setDateInput] = useState(moment().format('YYYY-MM-DD'))
  const [amount, setAmount] = useState('')
  const [remark, setRemark] = useState('ok')
  const [imgUrl, setImgUrl] = useState('')
  const [invoice, setInvoice] = useState('')
  const [uploading, setUploading] = useState(false)
  const [bankAccount, setBankAccount] = useState(null)
  const [user, setUser] = useState({})

  async function submitApprove() {
    //validate
    if (imgUrl == null) {
      return alert('กรุณาอัพโหลดใบกำกับภาษีเต็มรูปแบบ')
    }

    if (_.isNaN(parseFloat(amount))) {
      return alert('ต้องกรอกเฉพาะตัวเลขช่องจำนวนเงิน')
    }
    // ok
    var res = window.confirm('ยืนยันการส่งข้อมูล?')
    if (!res) {
      return
    }
    try {
      var approved = {
        transferUrl: imgUrl,
        date: dateInput,
        amount: parseFloat(amount),
        amountText: amount,
        timestamp: moment().toISOString()
      }
      var params = {
        TableName: TABLE.COUPON_TABLE,
        Key: { mobile: match.params.mobile, couponId: match.params.id },
        UpdateExpression: 'set #approved = :approved, #status = :status ',
        ExpressionAttributeNames: {
          '#approved': 'approved',
          '#status': 'status'
        },
        ExpressionAttributeValues: {
          ':approved': approved,
          ':status': 'used'
        }
      }

      await documentClient.update(params).promise()

      alert('ข้อมูลถูกบันทึกแล้ว :)')

      // await sms(match.params.mobile, match.params.id, amount)
      await snsSendSms(match.params.mobile, match.params.id, amount)

      history.push('/')
    } catch (error) {
      alert(error)
      console.log(JSON.stringify(error))
    }
  }

  async function fetchUser() {
    try {
      var params = {
        TableName: TABLE.USERS_TABLE,
        Key: {
          mobile: match.params.mobile
        }
      }

      debugger
      var data = await documentClient.get(params).promise()
      setUser(data.Item || {})
      setBankAccount((data.Item && data.Item.bankAccount) || null)
    } catch (err) {
      alert(err)
    }
  }

   

  var snsSendSms = (window.SNS_TEST = async function(mobile, couponId, amount) {
    try {
      var text = `EatMan :  คูปองหมายเลข${couponId} ได้รับการโอนเงินคืนแล้วมูลค่า ${amount} บาท\n\nสามารถดูข้อมูลการโอนได้ที่  https://eatman.app`
      // var text = "TESTTT"
      var params = {
        Message: text /* required */,
        
        PhoneNumber: `+66` + mobile
      }
      var data = await sns.publish(params).promise()

      return data
    } catch (error) {
      console.log(error)
    }
  })

  useEffect(() => {
    fetchUser()
  }, [])
  return (
    <div>
      <Coupon user={{ mobile: match.params.mobile }} />
      <BankInfo bankAccount={bankAccount} />
      <div class="alert alert-success mt-3" role="alert">
        <h4 class="alert-heading">Approve</h4>

        <Form
          label="Transfer DateTime"
          value={dateInput}
          onChange={setDateInput}
          placeholder={'YYYY-MM-DD'}
        />
        <Form label="Amount" value={amount} onChange={setAmount} />
        <Form label="Remark" value={remark} onChange={setRemark} />
        <div class="form-group">
          <label for="exampleInputEmail1">ใบเสร็จสลิป</label>
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="customFile"
              onChange={handleImageOnChange}
              accept="image/png, image/jpeg"
              // ref={fileInputRef}
            />
            <label class="custom-file-label" for="customFile">
              {invoice || 'Choose file'}
            </label>
          </div>
        </div>

        {imgUrl && <img src={imgUrl} class="round img-thumbnail" />}

        <button
          onClick={submitApprove}
          type="button"
          class="btn btn-block mt-5 btn-success">
          Success
        </button>
      </div>
    </div>
  )
}

export default withRouter(Approved)

function genSmsUrl(mobile, text) {
  //var URL_ORI ='http://www.thsms.com/api/rest?method=send&username=riseres&password=riceengineer&from=0000&to=0818460621&message=Test'
  var URL =
    'http://www.thsms.com/api/rest?method=send&username=riseres&password=riceengineer&from=0000&to=MOBILE&message=TEXT'
  URL = URL.replace('MOBILE', mobile)
  var FINAL_URL = URL.replace('TEXT', text)

  return encodeURI(FINAL_URL)
}
