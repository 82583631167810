import React, { useState, useEffect, useReducer } from 'react'
import moment from 'moment'
import Coupon from '../components/Coupon'
import _ from 'lodash'
import { Link } from 'react-router-dom'
const aws = require('../../awsInstance')

function Home({ user = { mobile: '1234' }, history }) {
  const [coupons, setCoupons] = useState([])
  const [groupCoupons, setGroupCoupons] = useState({})

  const [used, setUsed] = useState(0)
  const [available, setAvailable] = useState(0)
  const [pending, setPending] = useState(0)
  const [total, setTotal] = useState(1)
  const [usedList, setUsedList] = useState([])

  async function fetchCoupon() {
    var params = {
      TableName: aws.TABLE.COUPON_TABLE,
      KeyConditionExpression: `mobile = :mobile`,
      ExpressionAttributeValues: {
        ':mobile': user.mobile
      }
    }
    var data = await aws.documentClient.query(params).promise()
    data.Items = data.Items.sort((a, b) => {
      return parseInt(b.price) - parseInt(a.price) >= 0 ? 1 : -1
    })
    setCoupons(data.Items)

    return data
  }
  useEffect(() => {
    fetchCoupon()
  }, [])

  useEffect(() => {
    var g = _.groupBy(coupons, c => c.status)
    var total = _.sumBy(coupons, 'price')
    var _active = _.sumBy(g['active'], 'price')
    var _used = _.sumBy(g['used'], c => c.approved.amount )
    var _pending = _.sumBy(g['pending'], 'price')
    setTotal(total)
    setAvailable(_active)
    setUsed(_used)
    setPending(_pending)
    setGroupCoupons(g)
    console.log(JSON.stringify(g['pending']))
    setUsedList(g['used'] || [])
  }, [coupons])

  return (
    <div class="mb-5">
      <h1>Good Morning, Enjoy</h1>
      <ProgessPercent
        percent={(available / total) * 100}
        label="Coupon Avaliable"
        text=""
        amount={available}
      />

      <div class="mb-3" />
      <ProgessPercent
        percent={ ((used / total) * 100).toFixed(0)}
        label="Used (Claim completed)"
        amount={used.toFixed(0)}
        type="success"
      />

      <div class="mb-4" />

      {_.map(groupCoupons['pending'], p => (
        <PendingCard {...p} {...p.claim} />
      ))}

      <div class="mb-5" />
      <BrandUsed usedList={usedList} />
    </div>
  )
}

export default Home

function ProgessPercent({ label, percent = 40, text, amount, type }) {
  return [
    <p class="mb-0" style={{ color: '' }}>
      {label}
      <stong>
        <span style={{ marginLeft: 16 }}>{text}</span>
      </stong>
    </p>,

    <div class="progress">
      <div
        class={`progress-bar bg-${type}`}
        role="progressbar"
        style={{ width: percent + '%' }}
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100">
        {amount}THB
      </div>
    </div>
  ]
}

export const PendingCard = function ({ couponId, mobile, brand, grandTotal, timestamp, isAdmin }) {
  return (
    <div class="card text-white bg-primary mb-3" style={{ maxWidth: '20rem' }}>
      <div class="card-header">คูปอง#{couponId} <span style={{float : 'right'}}>Pending...</span></div>
      <div class="card-body">
        <h5 class="card-title">{brand}</h5>
        <p class="card-text">
          Date : {moment(timestamp).format('DD/MMMM/YYYY')}
        </p>
        <p class="card-text">Amount : {grandTotal}THB</p>
        <Link to={isAdmin ?  `/${mobile}/${couponId}`:`/coupon/${couponId}`}  href="#" class="btn btn-light " style={{float: 'right'}}>View</Link>
      </div>
    </div>
  )
}

function BrandUsed({ usedList }) {
  const list_sorted = _.sortBy(usedList, l=> l.usedDate)

  return (
    <div class="card">
      <div class="card-header">Brand ที่เคยใช้บริการแล้ว</div>
      <ul class="list-group list-group-flush">
        {_.map(list_sorted, (l,i) => {
          return <li class="list-group-item">
          {i+1}.
            

            <span style={{marginLeft : 12}}>

           {l.claim.brand} 

</span>

<span style={{marginLeft : 8, fontStyle : 'italic', color : 'gray'}}>

               {moment(l.usedDate).format('DD/MMM')}

            </span>

              <Link to={`/coupon/${l.couponId}`}  href="#" class="card-link " style={{float: 'right'}}>View</Link>

          </li>
        })}
      </ul>
      {usedList && usedList.length == 0 && <div class="card-body">
    <blockquote class="blockquote mb-0">
      <footer class="blockquote-footer">ยังไม่มีร้านที่ใช้สิทธิ์แล้ว<cite title="Source Title"></cite></footer>
    </blockquote>
  </div>}
    </div>
  )
}
