import React, { Component, useState, useEffect } from 'react'
import moment from 'moment'
import  _   from 'lodash'
export default function PeopleView({ list, name, used, fullPrice, count , setViewCoupon, couponsList }) {

  const list_sorted = _.sortBy(list, l=> l.usedDate)

  return (
    <div class="card  mb-3" style={{   }}>
      <div class="card-header">
      {name}    
      <span  onClick={()=>setViewCoupon(null)}  style={{float : 'right'}}>Back</span>
      </div>
      <div class="card-body">
        <h5 class="card-title">total : {used.toFixed(0)}  ({count})    </h5>
         
        <table class="table table-sm" style={{fontSize: '0.92em'}}>
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Date</th>
      <th scope="col">Brand</th>
      <th scope="col">THB</th>
    </tr>
  </thead>
  <tbody>
    {list_sorted.map(  (c, i)=>{

      return (<tr>
        <th scope="row">{i+1}</th>
        <td>{moment(c.usedDate).format('DD/MMM')}</td>
        <td>{c.claim.brand}</td>
        <td>{c.approved.amount}</td>
      </tr>)
    })} 
    </tbody>
    </table>
      </div>
    </div>
  )
}
