import React, { useState, useEffect } from 'react'
import { s3, TABLE, documentClient, sns } from '../../awsInstance'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import Coupon from '../components/Coupon'
import Status from './Status'
import _ from 'lodash'

const CouponView = ({ user = { mobile: '1234' }, history, match }) => {
  function handleImageOnChange(evt) {
    var file = evt.currentTarget.files[0]
    var fileName = evt.currentTarget.files[0].name
    setInvoice(fileName)
    setUploading(true)

    s3.upload(
      {
        Key: `eatman/${user.mobile || 1234}/${moment().format(
          'YYYY-MM-DD'
        )}/${fileName}`,
        Bucket: 'v1-tax',
        Body: file
      },
      function(err, data) {
        setUploading(false)

        if (err) {
          return alert('There was an error uploading your photo: ', err.message)
        }
        setImgUrl(data.Location)
        // alert("Successfully uploaded photo.");

        setTimeout(() => {
          // window.scrollTo(0,document.body.scrollHeight);
          window.$(window).scrollTop(650)
          locationWhere()
        }, 400)
      }
    )
  }
  const [brand, setBrand] = useState('')
  const [canEdit, setCanEdit] = useState(true) //
  const [location, setLocation] = useState('')
  const [grandTotal, setGrandTotal] = useState('')
  const [coupon, setCoupon] = useState(null)
  const [invoice, setInvoice] = useState(null)
  const [imgUrl, setImgUrl] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [approved, setApproved] = useState(null)
  const [gpsPosition, setGpsPosition] = useState(null)
  async function submit() {
    if (_.some([brand, location, grandTotal], _.isEmpty)) {
      return alert('กรุณาใส่ข้อมูลให้ครบทุกช่อง')
    }
    //validate
    if (imgUrl == null) {
      return alert('กรุณาอัพโหลดใบกำกับภาษีเต็มรูปแบบ')
    }
    if (_.isNaN(parseFloat(grandTotal))) {
      return alert('ต้องกรอกเฉพาะตัวเลขช่องจำนวนเงิน')
    }
    // ok
    var res = window.confirm('ยืนยันการส่งข้อมูล?')
    if (!res) {
      return
    }


    //check is Expired
    let endDateMoment =  moment(coupon.endDate).endOf('day');
    let isExpired = moment().isAfter(endDateMoment, 'second')
    if(isExpired){
      window.alert('Sorry, this coupon is expired.');
      return 
    }

    try {
      var claim = {
        invoiceUrl: imgUrl,
        brand,
        location,
        grandTotal: parseFloat(grandTotal),
        grandTotalText: grandTotal,
        timestamp: moment().toISOString(),
        gpsPosition :gpsPosition
      }
      var params = {
        TableName: TABLE.COUPON_TABLE,
        Key: { mobile: coupon.mobile, couponId: coupon.couponId },
        UpdateExpression:
          'set #claim = :claim, #status = :status, #usedDate = :usedDate ',
        ExpressionAttributeNames: {
          '#claim': 'claim',
          '#status': 'status',
          '#usedDate': 'usedDate'
        },
        ExpressionAttributeValues: {
          ':claim': claim,
          ':status': 'pending',
          ':usedDate': moment().format('YYYY-MM-DD')
        }
      }
      await documentClient.update(params).promise()

      alert('ข้อมูลถูกบันทึกแล้ว :)')
      await sendSMS(coupon.mobile, coupon.couponId, grandTotal)
      history.push('/thankyou/' + coupon.couponId)
    } catch (error) {
      alert(error)
    }
  }

  async function sendSMS(mobile, couponId, amount) {
    var text = `EatMan :  คูปองหมายเลข${couponId} อยู่ในสถานะ pending... ${amount} บาท`
    // var text = "TESTTT"
    var params = {
      Message: text /* required */,
      PhoneNumber: `+66` + mobile
    }

    // sms to owner coupon
    var data = await sns.publish(params).promise()

    // sms to rice
    var params = {
      Message: text /* required */,
      PhoneNumber: `+660818460621`
    }
    var data = await sns.publish(params).promise()
  }
  async function fetchCoupon() {
    try {
      var params = {
        TableName: TABLE.COUPON_TABLE,
        Key: {
          mobile: user.mobile,
          couponId: match.params.id
        }
      }

      var data = await documentClient.get(params).promise()
      setCoupon(data.Item)
      var c = data.Item || {}
      if (c.status == 'active') {
        setCanEdit(true)
        
          locationWhere()
    
        
      } else {
        //other status
        setCanEdit(false)
        setLocation(c.claim.location)
        setBrand(c.claim.brand)
        setGrandTotal(c.claim.grandTotal)
        setImgUrl(c.claim.invoiceUrl)
        setApproved(c.approved)
        setGpsPosition(c.claim.gpsPosition || null)
        locationWhere(c.claim.gpsPosition||null )

      }
      return
    } catch (error) {
      alert(error)
    }
  }
  useEffect(async () => {
    await fetchCoupon()
    
  }, [   ])

  function locationWhere(forceGps) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position)=>{
        setGpsPosition(forceGps || position)
        setTimeout(()=>{
          showPosition(forceGps || position)
        },500)
      })
    }
    function showPosition(position) {
      var lat = position.coords.latitude
      var lon = position.coords.longitude
      var latlon = new window.google.maps.LatLng(lat, lon)
      var mapholder = document.getElementById('map')
      mapholder.style.height = '250px'
      mapholder.style.width = '100%'

      var myOptions = {
        center: latlon,
        zoom: 15,
        draggable: false,
        mapTypeId: window. google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false,
        navigationControlOptions: {
          style: window.google.maps.NavigationControlStyle.SMALL
        }
      }
      var map = new window.google.maps.Map(
        document.getElementById('map'),
        myOptions
      )
      var marker = new window.google.maps.Marker({
        position: latlon,
        map: map,
        title: 'You are here!'
      })
    }

     
  }
  if (uploading) {
    return [
      <div class="d-flex justify-content-center mb-5">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>,
      <div class="text-center mb-3">
        <strong>Uploading Image...</strong>
      </div>,
      <div class="text-center">
        <strong>please wait</strong>
      </div>
    ]
  }

  return (
    <div>
      {coupon && coupon.status != 'active' && (
        <Status
          text={coupon.status}
          type={coupon.status == 'used' ? 'success' : undefined}
        />
      )}
      {coupon && <Coupon readOnly={true} {...coupon} />}
      {approved && [
          <h4>Transfer Completed</h4>,
          <img src={approved.transferUrl} class="round img-thumbnail" />
        
      ]}
      <hr />
      <h4>Detail</h4>
      <Form
        label="ชื่อร้าน / Brand"
        value={brand}
        onChange={setBrand}
        readonly={!canEdit}
      />
      <Form
        readonly={!canEdit}
        label="สถานที่ หรือ สาขา"
        value={location}
        onChange={setLocation}
      />
      <Form
        label="ยอดเงินสุทธิ"
        type="number"
        value={grandTotal}
        readonly={!canEdit}
        onChange={setGrandTotal}
      />

      {canEdit && (
        <div class="form-group">
          <label for="exampleInputEmail1">รูปถ่ายใบกำกับภาษีเต็มรูปแบบ</label>
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="customFile"
              onChange={handleImageOnChange}
              accept="image/png, image/jpeg"
              // ref={fileInputRef}
            />
            <label class="custom-file-label" for="customFile">
              {invoice || 'Choose file'}
            </label>
          </div>
          <small id="emailHelp" class="form-text text-muted">
            ใบกำกับ ชื่อและนามสกุล จะต้องตรงกับชื่อที่สมัครในระบบEatManเท่านั้น
          </small>
        </div>
      )}

      {imgUrl && <img src={imgUrl} class="round img-thumbnail" />}

      {gpsPosition && <h4>Location</h4>}
      <div id="map"> </div>
      {canEdit && (
        <button
          onClick={submit}
          type="button"
          class="mt-5 btn btn-primary btn-lg btn-block">
          Submit
        </button>
      )}
    </div>
  )
}

export const Form = ({
  label,
  helper,
  placeholder,
  readonly,
  value,
  onChange,
  type = 'text'
}) => (
  <div class="form-group">
    <label for="exampleInputEmail1">{label}</label>
    <input
      type={type}
      class={'form-control'}
      placeholder={placeholder}
      value={value}
      disabled={readonly}
      onChange={evt => (readonly ? '' : onChange(evt.target.value))}
    />
    {helper && (
      <small id="emailHelp" class="form-text text-muted">
        {helper}
      </small>
    )}
  </div>
)
export default withRouter(CouponView)
