import React from 'react'

const Thankyou = ({ match }) => (
    <div class="jumbotron">
    <h1 class="display-4">Thank you!</h1>

    <h1 >Coupon  <strong style={{color : 'blue'}}>#{match.params.id }</strong></h1>

    <p class="lead">EatMan ได้รับข้อมูลของคุณแล้ว</p>
    <p class="lead">ทางทีมงานจะตรวจข้อมูล และโอนเงินกลับให้คุณในวันทำการถัดไป</p>

   </div>
)

export default Thankyou
