import React, { useState } from 'react'
import { TABLE, documentClient } from '../../awsInstance'
const Login = () => {
  const [input, setInput] = useState('')

  async function login() {
    try {
      var params = {
        TableName: TABLE.USERS_TABLE,
        Key: {
          mobile: input.trim()
        }
      }

      var data = await documentClient.get(params).promise()
      if (data.Item) {
        window.localStorage.setItem('mobileCache', input.trim())
        window.location.reload()
      } else {
        alert('ไม่พบ USER')
      }
      return
    } catch (error) {
      alert(error)
    }
  }

  return (
    <div>
      <div class="col-md-5 mx-auto">
        <div id="first">
          <div class="myform form ">
            <div class="logo mb-3">
              <div class="col-md-12 text-center">
                <h1>EatMan Login</h1>
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Mobile</label>
              <input
                class="form-control"
                value={input}
                type="number"
                placeholder="ใส่เบอร์มือถือ"
                onChange={evt => setInput(evt.target.value)}
              />
            </div>
            <div class="form-group">
              <p class="text-center">
                By signing up you accept our <a href="#">Terms Of Use</a>
              </p>
            </div>
            <div class="col-md-12 text-center ">
              <button
                type="submit"
                onClick={login}
                class=" btn btn-block mybtn btn-primary tx-tfm">
                Login
              </button>
            </div>
            <div class="col-md-12 mt-5">
              <div class="form-group">
                <p class="text-center" />
                <p class="text-center">หรือ</p>
                <p class="text-center" />
              </div>
            </div>
            <div class="row">
              <div class="container">
                <blockquote class="quote-card green-card">
                  <p>หลังจากLoginสำเร็จแนะนำให้ Add to HomeScreen </p>
                  <br/>
                  <p>เพราะทำให้การใช้งานเหมือน APP และจะสะดวกต่อการใช้งานมากขึ้น :)</p>

                  <cite>หรือสร้าง bookmark ไว้</cite>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
