


  import React, { Component , useState, useEffect} from 'react';
  import { TABLE, documentClient } from '../../awsInstance'
  import _ from 'lodash'
import PeopleView from './peopleView'
export default function Summary() {
    const [coupons, setCoupons] = useState([])
    const [usedList, setUsedList] = useState([])
    const [sum, setSum] = useState(0)
    const [counts, setCounts] = useState(0)
    const [viewCoupon , setViewCoupon] = useState(null);
    async function fetch() {
        try {
          var params = {
            TableName: TABLE.COUPON_TABLE,
            FilterExpression: '#status = :status',
            ExpressionAttributeNames: { '#status': 'status' },
            ExpressionAttributeValues: { ':status': 'used' }
          }
    
          var data = await documentClient.scan(params).promise()
          setCoupons(data.Items || []);
          var sum = 0
          var counts =  0 ;
          var res = _.reduce(data.Items || [], (acc, c)=>{

            if(acc[c.nickName] == undefined ){
                acc[c.nickName] = {
                    count : 0 ,
                    used : 0,
                    fullPrice : 0,
                    name  : c.nickName,
                    list : []
                }
            }
            acc[c.nickName].list.push(c);
            acc[c.nickName].used += c.approved.amount;
            acc[c.nickName].fullPrice += c.price;
            acc[c.nickName].count ++;
            sum += c.approved.amount;
            counts++
            return acc;
          },{})
          setSum(sum);
          setCounts(counts)
          setUsedList(res)
          return
        } catch (error) {
          alert(error)
        }
      }
    
      useEffect(() => {
        fetch()
      }, [])

      if(viewCoupon){
        return  <PeopleView {...viewCoupon} setViewCoupon={setViewCoupon}    />
      }

    return (
    <div>
        <h4 class="mb-3">Used : <strong>{sum}</strong>THB,   <span class='ml-4'>({counts})</span> </h4>
        {_.map(usedList, (line)=>{
            return (<UserSummary setViewCoupon={setViewCoupon} {...line}/>)
        })}

    </div>)
}

function  UserSummary (props){
     let {name, used, fullPrice, count, setViewCoupon} = props
    return (<div class="card mb-4" >
    <div class='card-header'>
    {name} 

    <button class="btn btn-link"  style={{ float : 'right', padding : 0}} onClick={()=>{  ; setViewCoupon(props)}} >View</button>

    </div>
    <div class="card-body">
     
      <p class="card-text">Used :  <strong>{used}</strong> / {fullPrice}  </p>
      <p class="card-text">Count :  {count}   </p>
    </div>
  </div>)
}