

import React, { Component } from 'react';
import { PendingCard } from '../../containers/home';
import _ from 'lodash'

export default function({pendingList = []}){

    return (<div>
        <h1>Pending</h1>

        {_.map(pendingList, p=>{
            return (<PendingCard {...p} {...p.claim}  isAdmin={true}/>)
        })}
    </div>)
}