import React, { useReducer, useEffect, useState } from 'react'
import { Route, Link } from 'react-router-dom'
import CouponList from '../couponList/'
import User from '../user'
import Coupon from '../coupon'
import Thankyou from '../thankyou'
import { TABLE, documentClient } from '../../awsInstance'
import Loading from '../components/Loading'
import Login from '../login'
import Home from '../home'
import Docu from '../docu'
const appStateInit = {
  user: null,
  mobileCache: window.localStorage.getItem('mobileCache') || 'none',
  appState: 0 //0 = loading, 1 = to login, 2 = good OK
}

const App = () => {
  const [appState, dispatch] = useReducer(reducer, appStateInit)
  const [currentTab, setCurrentTab] = useState(0)
  async function fetchUser() {
    try {
      var params = {
        TableName: TABLE.USERS_TABLE,
        Key: {
          mobile: appStateInit.mobileCache
        }
      }

      var data = await documentClient.get(params).promise()
      if (data.Item) {
        dispatch({
          type: 'USER',
          payload: data.Item
        })
        dispatch({
          type: 'APP_STATE',
          payload: 2
        })
        window.ga('set', 'userId', data.Item.nickName || data.Item.mobile) // ตั้งค่า User ID โดยใช้ user_id ที่ลงชื่อเข้าใช้
      } else {
        dispatch({
          type: 'APP_STATE',
          payload: 1
        })
      }
      return
    } catch (error) {
      alert(error)
    }
  }

  function logout() {
    var res = window.confirm('Logout ?')
    if (res) {
      window.localStorage.removeItem('mobileCache')
      window.location.reload()
    }
  }
  useEffect(() => {
    fetchUser()
  }, [])

  if (appState.appState == 0) {
    return <Loading />
  }

  if (appState.appState == 1) {
    return <Login />
  }

  return (
    <div>
      <nav class="navbar   navbar-light bg-light">
        <a class="navbar-brand" href="#" style={{ color: 'white' }}>
          <span>EatMan: {appState.user && appState.user.mobile}</span>
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon" />
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
          <li class="nav-item active">
              <a class="nav-link" href="#" onClick={()=>  window.location.reload(true)}>
                Reload (Clear Cache) <span class="sr-only">(current)</span>
              </a>
            </li>
            <li class="nav-item active">
              <a class="nav-link" href="#" onClick={logout}>
                Logout <span class="sr-only">(current)</span>
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <main>
        <div class="container mb-5" style={{ marginTop: 24 }}>
          <Route
            exact
            path="/index.html"
            render={props => <Home {...props} user={appState.user} />}
          />

          <Route
            exact
            path="/"
            render={props => <Home {...props} user={appState.user} />}
          />
          <Route
            exact
            path="/docu"
            render={props => <Docu {...props} user={appState.user} />}
          />
          <Route
            exact
            path="/coupons"
            render={props => <CouponList {...props} user={appState.user} />}
          />
          <Route
            exact
            path="/user"
            render={props => <User {...props} user={appState.user} />}
          />
          <Route
            exact
            path="/coupon/:id"
            render={props => <Coupon {...props} user={appState.user} />}
          />
          <Route
            exact
            path="/thankyou/:id"
            render={props => <Thankyou {...props} user={appState.user} />}
          />
          <Route
            exact
            path="/login"
            render={props => <Login {...props} user={appState.user} />}
          />
          <div style={{ marginTop: 60, color: 'white' }}>_</div>
        </div>
      </main>

      <nav
        style={{ backgroundColor: '#ec5500 !important' }}
        class="navbar fixed-bottom navbar-light bg-light d-inline-flex   align-items-center justify-content-between">
        <FootIcon
          to={'/'}
          tab={0}
          label="Home"
          icon="fa fa-home"
          currentTab={currentTab}
          onClick={() => setCurrentTab(0)}
        />

        <FootIcon
          to={'/coupons'}
          tab={1}
          label="Coupons"
          icon="fa fa-credit-card"
          currentTab={currentTab}
          onClick={() => setCurrentTab(1)}
        />

        <FootIcon
          to={'/docu'}
          tab={2}
          currentTab={currentTab}
          onClick={() => setCurrentTab(2)}
          label="Document"
          icon="fa fa-file"
        />
        <FootIcon
          to={'/user'}
          tab={3}
          currentTab={currentTab}
          onClick={() => setCurrentTab(3)}
          label="User"
          icon="fa fa-user"
        />
      </nav>
    </div>
  )
}

function reducer(state, action) {
  switch (action.type) {
    case 'USER':
      return { ...state, user: action.payload }
      break
    case 'APP_STATE':
      return { ...state, appState: action.payload }
      break
    default:
      return state
      break
  }
}

export default App

function FootIcon({ label = 'text', icon, tab, currentTab = 0, to , onClick}) {
  const border = {
    //border: '1px solid black',
    //flex: '1 100%'
  }

  const isActive = tab == currentTab ? 'white' : 'white'
  const isActiveText = tab == currentTab ? 'underline' : ''

  return (
    <Link
       onClick={onClick}
      to={to}
      class="flex-fill d-flex flex-column  align-items-center"
      style={border}>
      <div class="align-self-center d-flex flex-column  align-items-center">
        {' '}
        <span style={{ fontSize: '1.5em' }}>
          <span style={{ color: isActive }}>
            <i class={`fas ${icon}`} />
          </span>
        </span>
        <span style={{ fontSize: '0.8em', color: isActive, textDecoration : isActiveText }}>{label}</span>
      </div>
    </Link>
  )
}
