import React, { useReducer, useEffect, useState } from 'react'
import { Route, Link } from 'react-router-dom'
 import PendingList from '../app/pendingList'
import { TABLE, documentClient } from '../../awsInstance'
 import SummaryAdmin from './summary'
import Approve from '../app/approve'
 

const App = () => {
  const [pendingList, setPendingList] = useState([])
  async function fetchPending() {
    try {
      var params = {
        TableName: TABLE.COUPON_TABLE,
        FilterExpression: '#status = :status',
        ExpressionAttributeNames: { '#status': 'status' },

        ExpressionAttributeValues: { ':status': 'pending' }
      }

      var data = await documentClient.scan(params).promise()
      setPendingList(data.Items || []);

      return
    } catch (error) {
      alert(error)
    }
  }

  useEffect(() => {
    fetchPending()
  }, [])

  return (
    <div>
      <nav class="navbar   navbar-light bg-light">
        <a class="navbar-brand" href="#" style={{ color: 'white' }}>
          <span>EatMan Admin</span>
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon" />
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item active">
              <Link class="nav-link" href="#" to={'/'}  > 
                Pending <span class="sr-only">(current)</span>
              </Link>
            </li>
            <li class="nav-item active">
              <Link class="nav-link" href="#" to={'/summary'}  > 
               Summary
              </Link>
            </li>

             
          </ul>
        </div>
      </nav>

      <main>
        <div class="container mb-5" style={{ marginTop: 24 }}>
          <Route
            exact
            path="/index.html"
            render={props => <PendingList {...props} pendingList={pendingList}  />}
          />
 <Route
            exact
            path="/admin"
            render={props => <PendingList {...props} pendingList={pendingList}  />}
          />

<Route
            exact
            path="/"
            render={props => <PendingList {...props} pendingList={pendingList}  />}
          />
           <Route
            exact
            path="/:mobile/:id"
            render={props => <Approve {...props}  />}
          /> 

          <Route
            exact
            path="/summary"
            render={props => <SummaryAdmin {...props}  />}
          /> 
          <div style={{ marginTop: 60, color: 'white' }}>_</div>
        </div>
      </main>

      <nav
        style={{ backgroundColor: '#ec5500 !important' }}
        class="navbar fixed-bottom navbar-light bg-light d-inline-flex   align-items-center justify-content-between">
        
          <p style={{color : 'white'}}>EatMan กินอาหารคุ้มกว่า</p>
           </nav>
    </div>
  )
}

export default App

function FootIcon({ label = 'text', icon, tab, currentTab = 0, to }) {
  const border = {
    //border: '1px solid black',
    //flex: '1 100%'
  }

  const isActive = tab == currentTab ? 'white' : '#ddd'
  return (
    <Link
      to={to}
      class="flex-fill d-flex flex-column  align-items-center"
      style={border}>
      <div class="align-self-center d-flex flex-column  align-items-center">
        {' '}
        <span style={{ fontSize: '1.5em' }}>
          <span style={{ color: isActive }}>
            <i class={`fas ${icon}`} />
          </span>
        </span>
        <span style={{ fontSize: '0.8em', color: isActive }}>{label}</span>
      </div>
    </Link>
  )
}
